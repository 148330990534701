import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Row, Col, List, Card } from 'antd';
import { client } from '@/utils/sanity-client';
import SocialShare from '@/components/SocialShare';
import BlockContent from '@sanity/block-content-to-react';

const CategoryPage = () => {
  const [documents, setDocuments] = useState([]);
  const router = useRouter();
  const { category } = router.query;

  useEffect(() => {
    if (category) {
      client
        .fetch(`*[_type == $category] | order(title asc)`, { category })
        .then((data) => {
          if (data && data.length > 0) {
            setDocuments(data);
          } else {
            // Redirect to the custom 404 page
            router.push('/404');
          }
        })
        .catch((error) => {
          console.error(error);
          router.push('/404');
        });
    }
  }, [category, router]);

  if (!documents.length) return <div>Loading...</div>;

  function formatCategory(category) {
    return category
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .replace(/^./, (str) => str.toUpperCase());
  }

  const formattedCategory = formatCategory(category);
  const fullUrl = `https://brandymail.com${router.asPath}`;
  const title = 'Brandymail.com - ' + formattedCategory;

  return (
    <div>
      <section className="knowledgebase section hero v9">
        <div className="container-default w-container">
          <div className="z-index-1">
            <div className="mg-bottom-78px">
              <div className="inner-container center">
                <div className="inner-container _861px---tablet center">
                  <div className="text-center">
                    <div className="mg-bottom-24px">
                      <div className="text-100 bold color-accent-1 text-uppercase">
                        Brandymail™ Knowledgebase
                      </div>
                    </div>
                    <h1 className="display-1 color-neutral-100 mg-bottom-24px">
                      {formattedCategory}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute card-top-bg---bottom pricing-bg" />
      </section>
      <div className="overflow-hidden border-radius-40px---bottom">
        <section className="section pd-top-0px bg-neutral-100 pd-0px">
          <div className="container-default w-container inner-container center">
            <Row gutter={[48, 48]}>
              <Col xs={24} md={16}>
                <h2>{formattedCategory}</h2>
                <List
                  bordered={false}
                  split={true}
                  className="mg-bottom-48px"
                  itemLayout="vertical"
                  dataSource={documents}
                  renderItem={(doc) => (
                    <List.Item key={doc._id}>
                      <h3>
                        {doc.slug && doc.slug.current ? (
                          <Link href={`/${category}/${doc.slug.current}`}>
                            {doc.title || doc.question || doc.name}
                          </Link>
                        ) : (
                          doc.title || doc.question || doc.name
                        )}
                      </h3>
                      {doc.definition && (
                        <BlockContent
                          blocks={doc.definition}
                          className="glossary-definition"
                        />
                      )}
                      {doc.fullPageLink && (
                        <div style={{ marginTop: '1rem' }}>
                          <Link href={doc.fullPageLink}>
                            <a target="_blank" rel="noopener noreferrer">
                              View full entry
                            </a>
                          </Link>
                        </div>
                      )}
                    </List.Item>
                  )}
                />
              </Col>
              <Col xs={24} md={8}>
                <Card
                  className="card post-author"
                  style={{
                    position: 'sticky',
                    top: '0',
                  }}
                >
                  <h4>Share this {formattedCategory} page</h4>
                  <SocialShare url={fullUrl} title={title} />
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CategoryPage;
