import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';

function SocialShare({ url, title }) {
  return (
    <div className="social-share-container">
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={32} round style={{ marginRight: '12px' }} />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={32} round style={{ marginRight: '12px' }} />
      </TwitterShareButton>

      <LinkedinShareButton url={url}>
        <LinkedinIcon size={32} round style={{ marginRight: '12px' }} />
      </LinkedinShareButton>

      <EmailShareButton url={url} subject={title} body="Check out this page: ">
        <EmailIcon size={32} round />
      </EmailShareButton>
    </div>
  );
}

export default SocialShare;
